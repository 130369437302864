import { Button, Form, Input, InputNumber ,Row,Col, message} from 'antd';
import React,{useState} from 'react';


const LeadFormV1 = (props) => { 
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const onFinish = (values) => {
        console.log(values);
        setLoading(prevLoadings => {
            return true
        });
      
        const requestOptions = {
            redirect: "follow",
            method: 'POST',
            headers: {"Content-Type": "text/plain;charset=utf-8"},
            body: JSON.stringify(values)
        };
        fetch("https://script.google.com/macros/s/AKfycbz7QPaCtPHxaAhmkrknsOYl_cZEFe1gt9CXJFT-RM5MwDwBRW8RURWPIS5xvzZaGz6m/exec",requestOptions).then((resp)=>{
            message.info("Thanks for your interest, Will reach back to you soon..");
            form.resetFields();
            setLoading(prevLoadings => {
                return false
            });
            window.location.href = "/#success";
        })
    }

    const layout = {
        size: "large",
        requiredMark: false,
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 8,
        },
    };

    const onReset = () => {
        form.resetFields();
    };
    
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${label} is required!',
        types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
        },
        number: {
        range: '${label} must be between ${min} and ${max}',
        },
    };
    /* eslint-enable no-template-curly-in-string */

    const { ...propss } = props;
    return (
        <Col
        {...propss}
        className="site-layout-background"
        style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            backgroundImage: "linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.8) 100%)"
        }}
        >
          <Form {...layout} form={form} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
                <Form.Item name={['Name']} label="Name" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={['Email']} label="Email" rules={[{ required: true ,type: 'email' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={['Mobile']} label="Mobile" rules={[{ required: true , type: 'mobile',  message: 'Please input your phone number!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                    <Button type="primary" htmlType="submit" loading={loading} >
                    Let me know more!
                    
                    </Button>
                </Form.Item>
            </Form>
        </Col>
    )
    
}

export default LeadFormV1;