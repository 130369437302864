import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page l9ipx5017mn-editor_css' },
  logo: {
    className: 'header3-logo',
    children:
      '/images/daBluu-6-full.svg',
      // '/images/daBluu-logo-5.svg'
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item1',
        className: 'header3-item l9ipxd7hakc-editor_css',
        // title: "Home",
        children: {
          href: '#home',
          children: [
            {
              children: (
                <span>
                  <p>Home</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header3-item l9ipxd7hakc-editor_css',
        // title: "Home",
        children: {
          href: '#features',
          children: [
            {
              children: (
                <span>
                  <p>Features</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item4',
        className: 'header3-item l9ipxd7hakc-editor_css',
        children: {
          href: '#team',
          children: [
            {
              children: (
                <span>
                  <p>Team</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header3-item l9ipxd7hakc-editor_css',
        children: {
          href: '#knowmore',
          children: [
            {
              children: (
                <span>
                  <p>Know more</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5 l9jovv7cyj-editor_css' },
  page: { className: 'home-page banner5-page l9ipwvg9bit-editor_css' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              {/* <p>go limitless</p> */}
              {/* <p>Charge anxiety is the story of past, be limitless..</p> */}
              <p><span style={{display:'block',whiteSpace: "nowrap"}}><span><span class="logo-title da">da</span><span class="logo-title team">True</span></span> EV charging</span> network for India</p>
            </span>
          </span>
        ),
        className: 'banner5-title',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: (
          <span>
            {/* <p>Introducing dabluu....</p> */}
            <p>Charge anxiety is the story of past, <span><span class="logo-title da">da</span><span class="logo-title team">Bluu</span></span> is here</p>
          </span>
        ),
      },
      // {
      //   name: 'content',
      //   className: 'banner5-content',
      //   children: (
      //     <span>
      //       <span>
      //         <p>step into the future</p>
      //       </span>
      //     </span>
      //   ),
      // },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '#knowmore',
          className: 'banner5-button',
          type: 'primary',
          children: (
            <span>
              <p>Know More</p>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      '/images/background.png',
  },
};
export const Content120DataSource = {
  wrapper: { className: 'home-page-wrapper content12-wrapper' },
  page: { className: 'home-page content12' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      { name: 'title', children: '特别鸣谢', className: 'title-h1' },
    ],
  },
  block: {
    className: 'img-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/TFicUVisNHTOEeMYXuQF.svg',
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/hkLGkrlCEkGZeMQlnEkD.svg',
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/bqyPRSZmhvrsfJrBvASi.svg',
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/UcsyszzOabdCYDkoPPnM.svg',
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/kRBeaICGexAmVjqBEqgw.svg',
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/ftBIiyJcCHpHEioRvPsV.svg',
          },
        },
      },
    ],
  },
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: {
    className: 'home-page content6 l9kvtdact7-editor_css',
    appear: true,
    replay: false,
  },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '蚂蚁金融云提供专业的服务',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '基于阿里云计算强大的基础资源',
      },
    ],
  },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/VHGOVdYyBwuyqCx.png',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          className: 'content6-icon',
        },
        title: { className: 'content6-title', children: '技术' },
        content: {
          className: 'content6-content',
          children:
            '丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。',
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
        },
        title: { className: 'content6-title', children: '融合' },
        content: {
          className: 'content6-content',
          children:
            '解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。',
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
        },
        title: { className: 'content6-title', children: '开发' },
        content: {
          className: 'content6-content',
          children:
            '符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。',
        },
      },
    ],
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: '/images/oie_KEkecfY3B9ix.png',
    // children: '/images/g2880.png'
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Charge anywhere' },
  content: {
    className: 'content1-content',
    children:
      'With our seamless charger integrations we enable cross aggregator charging possible without any new applications.'},
};

export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: '/images/attractmorecustomers.png',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: { className: 'content2-title', children: 'More customer for CPO’s' },
  content: {
    className: 'content2-content',
    children:
      'Are you a CPO with your charger available in only one network expand your presence with our interoperable charging infrastructure.',
  },
};

export const Feature11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'images/morechargingoption.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Give more access to your customers' },
  content: {
    className: 'content1-content',
    children:
      'If you own or operate multiple charging stations we enable you to expand your customers charger access without physically expanding your chargers.'},
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper l9jkfvphvsr-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      // {
      //   name: 'image',
      //   children:
      //     'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
      //   className: 'title-image l9jmty0otx-editor_css',
      // },
      // {
      //   name: 'title',
      //   children: 'India\'s true EV charging network',
      //   className: 'title-h1 l9jmpevze4s-editor_css',
      // },
      {
        name: 'title',
        children: 'go beyond borders',
        className: 'title-h1 l9jmpevze4s-editor_css',
      },
      // <p>go limitless</p>
      {
        name: 'content',
        children:
          'Unified interoperable EV charging infrastructure to charge without borders.',
        className: 'title-content l9jmpi9mzsq-editor_css',
      },
      {
        name: 'content2',
        children: 'OCPP compatible network',
        className: 'title-content l9jmpjn3x0r-editor_css',
      },
    ],
  },
  button: {
    className: '',
    children: {
      a: {
        className: 'button l9jmplkyrjb-editor_css',
        href: '#',
        children: 'Know More',
      },
    },
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'Why dabluu?' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>Charge anywhere</p>
                </span>
              ),
            },
            { name: 'content', children: 'With our seamless charger integrations we enable cross aggregator charging possible without any new applications.' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                '/images/attractmorecustomers.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>More customer for CPO’s</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: 'Are you a CPO with your charger available in only one network expand your presence with our interoperable charging infrastructure.',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Give more access to your customers.</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: 'If you own or operate multiple charging stations we enable you to expand  your customers charger access without physically expanding your chargers',
            },
          ],
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children:  (
          <span>
            <span class="logo-title da">da</span>
            <span class="logo-title team">Features</span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>dabluu adds value to everyone in the system!</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: '/images/charge-anxiety-2.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Solving charge Anxiety' },
          content: {
            className: 'content3-content',
            children:
              'We are building a unified interoperable charging infrastructure which enables EV’s to charge anywhere with the existing apps no more individual apps for new stations.',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
            '/images/moneybag.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>More revenue for CPO’s</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                CPOs can integrate directly to dabluu and get more customers via the network and guess what integrations are completely free!. Guess what individual CPO is finally possible, Your charger your pricing Our network lets the operator to decide the pricing per Kwh, Per unit or in a proper staircase model.
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              '/images/Subject.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'More reach to aggregators.' },
          content: {
            className: 'content3-content',
            children:
              'We understand that multiple aggregators means more fragmented EV infrastructure. We allows aggregators to connect to dabluu and extend their ev stations to other aggregators customers from the aggregators own app.',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              '/images/8684.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Charger installations can be worry free!' },
          content: {
            className: 'content3-content',
            children:
              'If you provide installation of charger infrastructure you can be confident about the ev network where you onboard your customers.  We provide a world class charging network infrastructure so that you don\'t need to manage it for your customers.',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              '/images/3515462.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'All in one cloud dashboard.' },
          content: {
            className: 'content3-content',
            children:
              'We are providing all of your charging information regardless of being an individual CPO or aggregator in the same world class format with auto intimation of charger faults, Power Failures, and total utilizations , without you lifting a finger, accessible anywhere in world.',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: '/images/2322706.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'No more learning curves.' },
          content: {
            className: 'content3-content',
            children:
              'You can charge at any of our networks with the apps which are familiar with you. No more new apps or  no new learning process.Just seamless charging.',
          },
        },
      },
    ],
  },
};
export const Teams10DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  page: { className: 'home-page teams1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: (<span><span class="logo-title da">da</span><span class="logo-title team">Team</span></span>) }],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 12,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image l9jk0g0p7wg-editor_css',
              children:
                "/images/raj.jpeg",
            },
            { 
              name: 'title', 
              className: 'teams1-title',
              children: <a href="https://www.linkedin.com/in/rgplvr/"  style={{'color':'blue'}}  target="_blank" rel="noopener noreferrer">Rajagopal</a> 
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: 'Co Founder',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'Ex Ola, Ex Amazon',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 12,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                '/images/DSC_0010-4.jpeg',
            },
            { 
              name: 'title', 
              className: 'teams1-title',
              children: <a href="https://www.linkedin.com/in/ysaakpr/" style={{'color':'blue'}}  target="_blank" rel="noopener noreferrer">Vyshakh</a> 
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: 'Co Founder',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children:
                'Ex Livspace , Captainfresh and Ex Amazon',
            },
          ],
        },
      },
      // {
      //   name: 'block2',
      //   className: 'block',
      //   md: 8,
      //   xs: 24,
      //   titleWrapper: {
      //     children: [
      //       {
      //         name: 'image',
      //         className: 'teams1-image',
      //         children:
      //           '/images/3551739.jpeg',
      //       },
      //       { name: 'title', className: 'teams1-title', children: 'Xxxhnx' },
      //       {
      //         name: 'content',
      //         className: 'teams1-job',
      //         children: 'Co Founder',
      //       },
      //       {
      //         name: 'content1',
      //         className: 'teams1-content',
      //         children:
      //           'Ex Xxxplx Ex Xxa',
      //       },
      //     ],
      //   },
      // },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          name: 'image',
          className: 'logo',
          children: (
            <span><span class="logo-title da">da</span><span class="logo-title team">Bluu</span></span>
          )
            // '/images/logo-full-3.svg',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: 'You can step into the future, we are building for the future..',
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: 'Quick links' },
        childWrapper: {
          children: [
            { name: 'link0', href: '#home', children: 'Home' },
            { name: 'link1', href: '#features', children: 'Features' },
            { name: 'link2', href: '#knowmore', children: 'Know more' },
            { name: 'link3', href: '#team', children: 'Team' },
            { name: 'linkedIn', target:"_blank", rel:"noopener noreferrer",href: 'https://www.linkedin.com/company/dabluu', children:"LinkedIn" },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: 'References' },
        childWrapper: {
          children: [
            { href: 'https://www.openchargealliance.org/protocols/ocpp-16/', name: 'link0', children: 'OCPP 1.0' },
            { href: 'https://www.openchargealliance.org/protocols/ocpp-201/', name: 'link0', children: 'OCPP 2.0' },
            { href: '#faq', name: 'link1', children: 'FAQ' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: 'Credits' },
        childWrapper: {
          children: [
            { href: 'https://www.freepik.com/', name: 'link0', children: 'freepik.com' },
            { href: 'https://ant.design/', name: 'link1', children: 'Ant designs' },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        copyright © 2022 <a href="https://dabluu.com"><span><span class="copyright">Voltzone technologies private limited.</span></span></a> All Rights
        Reserved
      </span>
    ),
  },
};
